<template>
  <b-row class="match-height">
    <b-col xl="5" md="5" sm="12">
      <b-card no-body>
        <b-card-header b-card-header class="align-items-baseline">
          <div>
            <b-card-title class="mb-25"> Sale Revenue </b-card-title>
          </div>
          <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
            <feather-icon id="sale-price-settings-icon" size="16" icon="SettingsIcon" class="cursor-pointer" />

            <!-- Setting Item Form -->
            <b-popover triggers="click" target="sale-price-settings-icon" placement="righttop">
              <b-form @submit.prevent>
                <b-row>
                  <b-col cols="8" class="pr-0" style="padding-right:0.5rem !important">
                    <flat-pickr placeholder="Sale Date Range" v-model="rangeDateSaleReportPrice" onClose="testClose" class="form-control" :config="config" />
                  </b-col>
                  <b-col cols="3" class="pl-0">
                    <b-button @click="clearPieDate()" variant="success">Clear</b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-popover>
          </div>
        </b-card-header>

        <b-card-body>
          <b-overlay :show="loadingPieChart" rounded="sm" no-fade>
            <div>
              <h3 class="mt-1">$ {{ formatPrice(netRevenue, 2) }}</h3>
              <h6 class="mb-0">Net Revenue</h6>
              <app-echart-doughnut :series="series" />
            </div>
          </b-overlay>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col xl="7" md="7" sm="12">
      <b-card no-body>
        <b-card-header class="align-items-baseline">
          <div>
            <b-card-title class="mb-25">
              Sales By User
            </b-card-title>
            <b-card-text class="mb-0"> </b-card-text>
          </div>
          <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
            <feather-icon id="sale-item-settings-icon" size="16" icon="SettingsIcon" class="cursor-pointer" />

            <!-- Setting Item Form -->
            <b-popover triggers="click" target="sale-item-settings-icon" placement="righttop">
              <b-form @submit.prevent>
                <b-row>
                  <b-col cols="8" class="pr-0" style="padding-right:0.5rem !important">
                    <flat-pickr placeholder="Sale Date Range" v-model="rangeDateSaleReportItem" onClose="testClose" class="form-control" :config="config" />
                  </b-col>
                  <b-col cols="3" class="pl-0">
                    <b-button @click="clearBarDate()" variant="success">Clear</b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-popover>
          </div>
        </b-card-header>

        <b-card-body class="pb-0">
          <b-overlay :show="loadingBarChart" rounded="sm" no-fade>
            <app-echart-bar :option-data="option" />
          </b-overlay>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BFormGroup, BTable, BRow, BCol, BFormSelect, BPagination, BProgress, BCardBody, BTab, BTabs } from 'bootstrap-vue';

import store from '@/store';
import { onUnmounted } from '@vue/composition-api';
import accountingStoreModule from '@/views/accounting/accountingStoreModule.js';
import ReportsCard from '@core/components/statistics-cards/ReportsCard.vue';
import VueApexCharts from 'vue-apexcharts';
import { $themeColors } from '@themeConfig';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import AppEchartDoughnut from '@core/components/charts/echart/AppEchartDoughnut.vue';
import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue';
import _ from 'lodash';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BTable,
    BFormSelect,
    BPagination,
    BProgress,
    VueApexCharts,
    BCardBody,
    BTab,
    BTabs,

    vSelect,
    flatPickr,
    ReportsCard,
    Treeselect,
    AppEchartDoughnut,
    AppEchartBar,
  },
  directives: {
    Ripple,
  },

  setup() {
    const ACCOUNTING_APP_STORE_MODULE_NAME = 'accounting';

    // Register module
    if (!store.hasModule(ACCOUNTING_APP_STORE_MODULE_NAME)) store.registerModule(ACCOUNTING_APP_STORE_MODULE_NAME, accountingStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNTING_APP_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNTING_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {
    this.getUsers();
  },

  data() {
    return {
      config: {
        dateFormat: 'd/m/Y',
        mode: 'range',
      },
      rangeDateSaleReportItem: null,
      filterDateStartItemReport: null,
      filterDateEndItemReport: null,
      loadingBarChart: false,

      rangeDateSaleReportPrice: null,
      filterDateStartPriceReport: null,
      filterDateEndPriceReport: null,
      loadingPieChart: false,

      users: [],
      userOptions: [],
      netRevenue: 0,
      // pie chart
      series: [
        {
          name: '',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          colorBy: 'series',

          tooltip: {
            trigger: 'item',
            formatter: function(params) {
              let val = (params.value / 1).toFixed(2).replace(',', '.');
              let dataInValue = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              return '$' + dataInValue;
            },
          },

          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'outline',
                formatter: function(params) {
                  return params.data.name + '\n' + params.percent + '%\n';
                },
              },
              labelLine: {
                show: true,
              },
            },

            emphasis: {
              label: {
                show: true,
                position: 'left',
                textStyle: {
                  fontSize: '20',
                },
              },
            },
          },

          //   itemStyle: {
          //   normal: {
          //     label: {
          //       show: true,
          //       position: 'inner',
          //       formatter: function(params) {
          //         return params.percent + '%\n';
          //       },
          //     },
          //     labelLine: {
          //       show: false,
          //     },
          //   },
          //   emphasis: {
          //     label: {
          //       show: true,
          //       position: 'center',
          //       textStyle: {
          //         fontSize: '30',
          //         fontWeight: 'bold',
          //       },
          //     },
          //   },
          // },

          data: [],
          color: ['#3ba272', '#fc8452', '#9a60b4', '#ea7ccc', '#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de'],
        },
      ],

      //bar chart
      option: {
        xAxis: [
          {
            type: 'category',
            data: [],
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
          },
        ],
        grid: {
          left: '40px',
          right: '30px',
          bottom: '30px',
        },
        series: [
          {
            realtimeSort: true,
            type: 'bar',
            stack: 'advertising',
            data: [],
            barMaxWidth: '20%',
            barMinWidth: '10px',
          },
        ],
      },
    };
  },
  methods: {
    getReportItemsPrice() {
      this.loadingPieChart = true;
      store
        .dispatch('accounting/saleReport', {
          start: this.filterDateStartPriceReport,
          end: this.filterDateEndPriceReport,
          type: 'sale',
          filterUser: this.users,
        })
        .then((response) => {
          if (response.status == 200) {
            this.series[0].data = [];
            this.netRevenue = 0;
            response.data.forEach((user) => {
              if (user.data.totalOrders + user.data.totalSelling > 0) {
                let totalRevenue = 0;

                this.netRevenue += user.data.totalOrders + user.data.totalSelling;
                this.series[0].data.push({
                  value: (user.data.totalOrders + user.data.totalSelling).toFixed(2),
                  name: user.userName,
                });
              }
            });
            this.series[0].name = `Total : $${this.formatPrice(this.netRevenue, 2)}`;
            this.loadingPieChart = false;
          }
        });
    },

    getReportItemsCount() {
      this.loadingBarChart = true;
      store
        .dispatch('accounting/saleReport', {
          start: this.filterDateStartItemReport,
          end: this.filterDateEndItemReport,
          type: 'sale',
          filterUser: this.users,
        })
        .then((response) => {
          if (response.status == 200) {
            this.option.series[0].data = [];
            this.option.xAxis[0].data = [];

            response.data = _.orderBy(response.data, ['data.count'], ['desc']);
            response.data.forEach((user) => {
              if (user.data.count > 0) {
                this.option.series[0].data.push(user.data.count);
                this.option.xAxis[0].data.push(user.userName);
              }
            });
            this.loadingBarChart = false;
          }
        });
    },

    getUsers() {
      this.loading = true;
      store.dispatch('accounting/fetchUsers', {}).then((res) => {
        this.userOptions = res.data;

        this.userOptions.forEach((element) => {
          let setStatus = {
            id: element.value,
            label: element.label,
            value: element.value,
            is: 'status',
          };
          this.users.push(setStatus);
        });
        this.getReportItemsPrice();
        this.getReportItemsCount();
      });
    },

    clearPieDate() {
      if (this.rangeDateSaleReportPrice != null && this.rangeDateSaleReportPrice != '') {
        this.filterDateStartPriceReport = null;
        this.filterDateEndPriceReport = null;
        this.rangeDateSaleReportPrice = null;
        this.getReportItemsPrice();
      }
    },

    clearBarDate() {
      if (this.rangeDateSaleReportItem != null && this.rangeDateSaleReportItem != '') {
        this.filterDateStartItemReport = null;
        this.filterDateEndItemReport = null;
        this.rangeDateSaleReportItem = null;
        this.getReportItemsCount();
      }
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    formatPriceDonut(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return parseFloat(val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
      }
    },
  },
  watch: {
    rangeDateSaleReportItem: {
      handler: function(val, before) {
        if (val != null) {
          if (val.includes('to')) {
            this.filterDateStartItemReport = this.rangeDateSaleReportItem.split(' to ')[0];
            this.filterDateEndItemReport = this.rangeDateSaleReportItem.split(' to ')[1];

            this.getReportItemsCount();
          }
        }
      },
    },

    rangeDateSaleReportPrice: {
      handler: function(val, before) {
        if (val != null) {
          if (val.includes('to')) {
            this.filterDateStartPriceReport = this.rangeDateSaleReportPrice.split(' to ')[0];
            this.filterDateEndPriceReport = this.rangeDateSaleReportPrice.split(' to ')[1];

            this.getReportItemsPrice();
          }
        }
      },
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.echarts {
  width: auto;
}
</style>
